import CatItem from '../catalog/catalog/components/CatItem/CatItem';
import Pl from '../../components/Pl/Pl';
import CreateCategory from '../catalog/modals/createCategory/CreateCategory';
import { useSelector } from 'react-redux';
import { useEffect, useCallback, useState, useMemo } from 'react';
import catService from '../../services/catService';
import Loader from '../../components/Loader/Loader';
import { motion } from 'framer-motion';
import GridToggle from '../../components/GridToggle/GridToggle';
import authService from '../../services/dataService';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import useGridType from '../../hooks/useGridType';
import { useRef } from 'react';
import pageEnterAnimProps from '../../funcs/pageEnterAnimProps';
import MiniCat from '../../components/MiniCat/MiniCat';
import { BiBorderRadius } from 'react-icons/bi';

const as = new authService();
const cs = new catService();

const PlateHits = () => {
    const { token, city } = useSelector((state) => state);
    const [createCategory, setCreateCategory] = useState(false);
    const [cats, setCats] = useState([]);
    const [load, setLoad] = useState(false);
    const [selectedCat, setSelectedCat] = useState(null);
    const [gridHeight, setGridHeight] = useState(250);
    const [boxRow, setBoxRows] = useState(5);
    const [rowHeight, setRowHeight] = useState(150);
    const itemBoxRef = useRef();
    const { gridType, setGridType } = useGridType();
    const [hits, setHits] = useState([]);

    const url = useMemo(() => {
        return new URLSearchParams(window.location.search);
    }, [window.location.search]);

    const getBoxWidth = useCallback(() => {
        if (itemBoxRef?.current) {
            if (gridType == 'small') {
                setBoxRows(Math.round((itemBoxRef.current.scrollWidth - 80) / 120));
            }
            if (gridType == 'big') {
                setBoxRows(Math.round((itemBoxRef.current.scrollWidth - 80) / 260));
            }
        }
    }, [gridType]);

    useEffect(() => {
        if (hits?.length > 0 && boxRow) {
            console.log(cats);
            if (hits.length % boxRow == 0) {
                setGridHeight(Math.round(hits.length / boxRow) * rowHeight + rowHeight);
            } else {
                setGridHeight(Math.round(hits.length / boxRow + 1) * rowHeight);
            }
        } else {
            setGridHeight(rowHeight);
        }
    }, [hits, boxRow, gridType, rowHeight]);

    useEffect(() => {
        if (gridType == 'big') {
            setRowHeight(280);
        } else {
            setRowHeight(110);
        }
        getBoxWidth();
        window.addEventListener('resize', getBoxWidth);
        return () => window.removeEventListener('resize', getBoxWidth);
    }, [gridType]);

    useEffect(() => {
        if (token) {
            setLoad(true);
            if (url.get('org')) {
                cs.getHitPlates(token, { Table: 'CategoriesMain', cityId: city.id })
                    .then((res) => {
                        // setCats(res);
                        res && setHits(Object.values(res));
                    })
                    .finally((_) => setLoad(false));
            } else {
                cs.getHitPlates(token, { Table: 'CategoriesMain', cityId: city.id })
                    .then((res) => {
                        // setCats(res);
                        res && setHits(Object.values(res));
                    })
                    .finally((_) => setLoad(false));
            }
        }
    }, [token, url, city.id]);

    const orderSort = async () => {
        try {
            const ids = hits?.map((item) => item.id);
            const requestBody = {
                page: 'CategoriesMain',
                content: {
                    id: ids,
                },
                cityId: city.id,
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        if (token && hits && hits.length > 0) {
            //   as.orderSort(token, 'categories', cats.map(item => item.ID).join(','))
            orderSort();
        }
    }, [token, hits]);

    const orderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
        if (sourceIndex == hits.length) {
            return;
        } else {
            const nextState = swap(hits, sourceIndex, targetIndex);
            setHits(nextState);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='CatalogPage page'
        >
            <CreateCategory
                setSelectedCat={setSelectedCat}
                editItem={selectedCat}
                updateList={setCats}
                visible={createCategory}
                close={() => setCreateCategory(false)}
            />
            <main className='Main'>
                <div className='pageBody'>
                    <div className='CatalogPage__body pageBody-content' ref={itemBoxRef}>
                        <GridToggle
                            selectBig={() => setGridType('big')}
                            selectSmall={() => setGridType('small')}
                        />
                        {load ? (
                            <Loader />
                        ) : (
                            <motion.div {...pageEnterAnimProps}>
                                <GridContextProvider onChange={orderChange}>
                                    <GridDropZone
                                        boxesPerRow={boxRow}
                                        style={{ height: gridHeight }}
                                        rowHeight={rowHeight}
                                    >
                                        {hits?.length &&
                                            hits?.map((item, index) => {
                                                if (gridType === 'big') {
                                                    return (
                                                        <GridItem className={'ddd__item'}>
                                                            <CatItem
                                                                Name={item.name}
                                                                key={item.id}
                                                                HideChangeButton={true}
                                                                styles={{
                                                                    border: '1px solid rgb(51, 51, 51, .2)',
                                                                    borderRadius: '10px',
                                                                }}
                                                            ></CatItem>
                                                        </GridItem>
                                                    );
                                                } else {
                                                    return (
                                                        <GridItem className={'ddd__item'}>
                                                            <MiniCat
                                                                Name={item.name}
                                                                key={item.id}
                                                                HideChangeButton={true}
                                                                styles={{
                                                                    border: '1px solid rgb(51, 51, 51, .1)',
                                                                    borderRadius: '10px',
                                                                }}
                                                            ></MiniCat>
                                                        </GridItem>
                                                    );
                                                }
                                            })}
                                    </GridDropZone>
                                </GridContextProvider>
                            </motion.div>
                        )}
                    </div>
                </div>
            </main>
        </motion.div>
    );
};

export default PlateHits;
